import moment from "moment";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { isValidHttpUrl } from "../../../helpers/utility";
import apiResponse from "../../../services/apiResponse";
import DetailBloc from "./Blocs/DetailBloc";
import MinioClient from "../../../services/minioClient";
import { Icon } from "@iconify/react";

class IndexView extends Component {
  detailBloc = new DetailBloc();
  minio = new MinioClient();

  constructor(props) {
    super(props);
    this.state = {};
  }

  fetchAttachment = async (path) => {
    const attachment = await this.minio.get(path).then(
      (res) => res,
      (err) => err
    );

    this.setState({
      detail: {
        ...this.state.detail,
        attachment_uri: attachment,
      },
    });
  };

  renderAttachment = (uri) => {
    const fileFormat = this.state.detail.attachment_name
      ? this.state.detail.attachment_name.split(".")[1]
      : null;
    let icon, size, mod;

    switch (fileFormat) {
      case "docx" || "doc":
        icon = "vscode-icons:file-type-word";
        break;
      case "pdf":
        icon = "vscode-icons:file-type-pdf";
        break;
      case "xls" || "xlsx":
        icon = "vscode-icons:file-type-excel";
        break;
      case "txt":
        icon = "vscode-icons:file-type-text";
        break;
      default:
        break;
    }

    if (this.state.detail.attachment_size) {
      size = parseInt(this.state.detail.attachment_size);
      switch (true) {
        case size > 900 && size < 899999:
          size /= 1000;
          mod = "KBytes";
          break;
        case size > 900000 && size < 899999999:
          size /= 1000000;
          mod = "MBytes";
          break;
        case size > 900000000:
          size /= 1000000000;
          mod = "GBytes";
          break;
        default:
          mod = "Bytes";
          break;
      }
    }

    return (
      <>
        {typeof uri === "string" ? (
          <a
            href={uri}
            target="_blank"
            rel="noreferrer"
            className="d-flex"
            style={{
              columnGap: ".75rem",
              width: "fit-content",
              padding: ".5rem",
              border: "1px solid #ddd",
              borderRadius: "6px",
            }}
          >
            <Icon icon={icon} style={{ width: "2rem", height: "auto" }} />
            <div style={{ width: "fit-content", display: 'flex', flexDirection: 'column' }}>
              <div>
                {this.state.detail.attachment_name}
              </div>
              <div style={{ color: "#999" }}>{`${size.toFixed(1)} ${mod}`}</div>
            </div>
          </a>
        ) : (
          <span
            className="d-flex"
            style={{
              columnGap: ".75rem",
              width: "fit-content",
              padding: ".5rem",
              border: "1px solid #ddd",
              borderRadius: "6px",
            }}
          >
            <em>Tidak ada notulensi.</em>
          </span>
        )}{" "}
      </>
    );
  };

  componentDidMount() {
    this.detailBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response;
            console.log("response: ", response);
            this.setState({
              detail: response,
            });
            this.fetchAttachment(this.state.detail.attachment.attachment_path);
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
    const queryParams = new URLSearchParams(this.props.location.search);
    this.detailBloc.fetchDetail({ guide_id: queryParams.get("id") });
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Detil Panduan</CardTitle>
                  <CardSubtitle className="mb-3">
                    Keterangan detil mengenai panduan
                  </CardSubtitle>
                  <hr />
                  <Row className="mb-3">
                    <label className="col-md-3">Judul</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.guide_name !== ""
                            ? this.state.detail.guide_name
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: 68, height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Kategori Panduan</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.category_name !== ""
                            ? this.state.detail.category_name
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Tipe Panduan</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.guide_type !== ""
                            ? this.state.detail.guide_type
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3">Tipe Video</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.video_type !== ""
                            ? this.state.detail.video_type
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>


                  <Row className="mb-3">
                    <label className="col-md-3">Link Youtube</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.youtube_link !== ""
                            ? this.state.detail.youtube_link
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md="3"></Col>
                    <Col md="9">
                      <Link to="panduan.html" className="waves-effect">
                        <Button color="danger">
                          <i className="uil-arrow-left"></i> Kembali
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

//export default IndexView
export default withRouter(IndexView);
